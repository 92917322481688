<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap">
      <h1>섹터관리 {{ isNew ? '등록' : '수정' }}</h1>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>기본정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd><label>회사명<span v-if="isNew" class="forg"> *</span></label>
            <ul>
              <li v-if="isNew">
                <common-multi-drop-down
                    ref="cmpyCdRef"
                    :params="{ searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
                    v-model="sectInfo.cmpyCd"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
                    :data-value-field="'cmpyCd'"
                    :data-text-field="'cmpyNm'"
                    @change="value=>{
                      propsCmpyCd = value
                      sectInfo.locaCd = ''
                      $refs.locaCdRef.widget().value('')
                      $refs.whCdRef.widget().value('')
                    }"
                >
                </common-multi-drop-down>
              </li>
              <li v-else>{{ sectInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>회사코드</label>
            <ul>
              <li>{{ sectInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>로케이션명<span v-if="isNew" class="forg"> *</span></label>
            <ul>
              <li v-if="isNew">
                <common-multi-drop-down
                    ref="locaCdRef"
                    @change="value=>{
                      propsLocaCd = value
                      sectInfo.whCd = ''
                      $refs.whCdRef.widget().value('')
                    }"
                    :disabled="!sectInfo.cmpyCd"
                    v-model="sectInfo.locaCd"
                    :params="{ searchCmpyCd : propsCmpyCd }"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/location`"
                    :data-value-field="'locaCd'"
                    :data-text-field="'locaNm'"
                    :all-params-validator="true"
                >
                </common-multi-drop-down>
              </li>
              <li v-else>{{ sectInfo.locaNm}}</li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>로케이션코드</label>
            <ul>
              <li>{{ sectInfo.locaCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>구역명<span v-if="isNew" class="forg"> *</span></label>
            <ul>
              <li v-if="isNew">
                <common-multi-drop-down
                    ref="whCdRef"
                    v-model="sectInfo.whCd"
                    :disabled="!sectInfo.locaCd"
                    :params="{ searchCmpyCd : propsCmpyCd, searchLocaCd : propsLocaCd}"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/wh`"
                    :data-value-field="'whCd'"
                    :data-text-field="'whNm'"
                    :all-params-validator="true"
                >
                </common-multi-drop-down>
              </li>
              <li v-else>{{ sectInfo.whNm }}</li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>구역코드</label>
            <ul>
              <li>{{ sectInfo.whCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>섹터명<span class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="sectNmRef"
                    class="k-textbox w100"
                    v-model.trim="sectInfo.sectNm"
                />
              </li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>섹터코드</label>
            <ul>
              <li>{{ sectInfo.sectCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>위치</label>
            <ul>
              <li>
                <input
                    ref="sectLocationRef"
                    class="k-textbox w100"
                    v-model.trim="sectInfo.sectLocation"
                />
              </li>
            </ul>
          </dd>
          <dd><label>위치이미지</label>
            <ul>
              <li>
                <common-file-upload
                    ref="sectLocationImgRef"
                    :url="`${ApiConfig.efsDomain}/efs/file/upload`"
                    :download-domain="ApiConfig.efsDomain"
                    :old-file-id="sectInfo.imgFileId"
                    v-model="sectInfo.imgFile"
                    :multiple="false"
                    :maxfile="1"
                    :allowed-extensions="['.jpg','.png']"
                    :layout="'PREVIEW'"
                >
                </common-file-upload>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>면적(평)</label>
            <ul>
              <li>
                <kendo-numerictextbox
                    ref="areaRef"
                    :format="'###,#'"
                    :min="0"
                    :max="999"
                    v-model="sectInfo.area">
                </kendo-numerictextbox>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>활성화여부</label>
            <ul>
              <li>
                <common-radio-group
                    ref="activeYnRef"
                    :data-items='[{label: "활성화", value: "Y",},{label: "비활성화", value: "N",}]'
                    v-model.trim="sectInfo.activeYn"
                ></common-radio-group>
              </li>
            </ul>
          </dd>

          <dd><label>비고</label>
            <ul>
              <textarea
                  ref="contentsRef"
                  v-model.trim="sectInfo.contents"
                  maxlength="200"
              ></textarea>
            </ul>
          </dd>
        </li>
        <li v-if="!isNew">
          <dd><label>등록</label>
            <ul>
              <li>{{sectInfo.frstRgstDt}} &nbsp | &nbsp {{sectInfo.frstRgstNm }}</li>
            </ul>
          </dd>
          <dd><label>최종수정</label>
            <ul>
              <li>{{sectInfo.lastMdfyDt}} &nbsp | &nbsp {{sectInfo.lastMdfyNm }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div v-if="!isNew" class="contents_wrap">
      <div class="small_title_wrap"><h2>상품재고정보</h2></div>
      <kendo-grid
          class="mt20"
          style="cursor: pointer; max-height: 368px"
          :data-source="stkGoodsList"
          :navigatable="false"
          :sortable-mode="'multiple'"
          :reorderable="true"
          :column-menu="false"
          :resizable="true"
          :selectable="'row'"
          :columns="gridColumns"
          :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
      </kendo-grid>

    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="onClickSectInfoSave">저장</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import Constant from '@/common/constant'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

export default {
  name      : 'SectorManageSave',
  created () {
    if (!!this.$route.params.cmpyCd
        && !!this.$route.params.locaCd
        && !!this.$route.params.whCd
        && !!this.$route.params.sectCd) {
      this.isNew = false
      this.getSectManageDetail()
    }
  },
  methods   : {
    getSectManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/sect/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}/${vm.$route.params.whCd}/${vm.$route.params.sectCd}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              response.data.resultData.imgFile.forEach(fileItem=>{
                fileItem.src = ApiConfig.efsDomain + "/efs/file/"+ fileItem.fileId + "/" + fileItem.fileSeqNo
                fileItem.name = fileItem.orgFileNm
                fileItem.size = fileItem.fileSize
              })
              vm.sectInfo = response.data.resultData
              vm.stkGoodsList = EtnersCommonUtil.setRowNumber(response.data.resultData.stkGoodsResponseList)
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    async onClickSectInfoSave () {
      const vm = this
      if (vm.validator()) {
        await kendo.confirm('저장 하시겠습니까?').done(
            async function () {
              await vm.sectManageSave()
            })
      }
    },
    async sectManageSave() {
      const vm = this
      try {
        vm.$store.dispatch('LOADING', true)
        let params = { loginUserToken: vm.$store.state.loginStore.lutk }
        if(vm.sectInfo.imgFile?.length > 0){
          let fileSaveReslut = await vm.$refs.sectLocationImgRef.saveFile(params)
          vm.$store.dispatch('LOADING', false)
          vm.sectInfo.imgFileId = EtnersCommonUtil.isNotEmpty(fileSaveReslut.fileId) ? fileSaveReslut.fileId : vm.sectInfo.imgFileId
        }else {
          vm.sectInfo.imgFileId = null
        }

        if(vm.isNew){
          ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/sect`, vm.sectInfo)
          .then(response => {

            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }
            vm.$store.dispatch('LOADING', false)
            vm.$router.push({
              name  : 'SectorManageDetail',
              params: {
                cmpyCd : response.data.resultData.cmpyCd,
                locaCd : response.data.resultData.locaCd,
                whCd : response.data.resultData.whCd,
                sectCd : response.data.resultData.sectCd
              }
            })
          })
        }else{
          ApiUtil.put(`${ApiConfig.efsDomain}/efs/manage/sect/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}/${vm.$route.params.whCd}/${vm.$route.params.sectCd}`, vm.sectInfo)
          .then(response => {

            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }
            vm.$store.dispatch('LOADING', false)
            vm.$router.push({
              name  : 'SectorManageDetail',
              params: {
                cmpyCd : vm.$route.params.cmpyCd,
                locaCd : vm.$route.params.locaCd,
                whCd : vm.$route.params.whCd,
                sectCd : vm.$route.params.sectCd
              }
            })
          })
        }
      }catch (e) {
        console.error(e)
        vm.$store.dispatch('LOADING', false)
      }

    },
    validator(){
      const vm = this
      if(!vm.sectInfo.cmpyCd){
        kendo.alert("회사를 선택해주세요.").bind('close', function() {
          vm.$refs.cmpyCdRef.widget().focus()
        })
        return
      }
      if(!vm.sectInfo.locaCd){
        kendo.alert("로케이션명을 선택해주세요.").bind('close', function() {
          vm.$refs.locaCdRef.widget().focus()
        })
        return
      }
      if(!vm.sectInfo.whCd){
        kendo.alert("구역을 선택해주세요.").bind('close', function() {
          vm.$refs.whRef.widget().focus()
        })
        return
      }
      if(!vm.sectInfo.sectNm){
        kendo.alert("섹터명을 입력해주세요.").bind('close', function() {
          vm.$refs.sectNmRef.focus()
        })
        return
      }
      return true
    },
  },
  data      : function () {
    const vm = this
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      Constant,
      vm: vm,
      propsCmpyCd : '',
      propsLocaCd : '',
      isWindowOpen : false,
      isNew : true,
      ApiConfig : ApiConfig,
      sectInfo  : {
        activeYn : 'Y'
      },
      stkGoodsList : [],
      gridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'itemNm',
          title: '품목',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'goodsCd',
          title: '상품코드',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'goodsNm',
          title: '상품명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'renewGbnNm',
          title: '상품분류',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'cnt',
          title: '재고수량',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return EtnersCommonUtil.numberWithCommas(dataItem.cnt)
          }
        }
      ],
    }
  },
}
</script>

<style scoped>
</style>
